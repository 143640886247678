// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// ä»¥ä¸ãã¡ã¤ã«ãæµç¨ãã¦ import
// âã®ãã¡ã¤ã«ã¯æ¬å®¶ https://github.com/twbs/icons ããç´æ¥ãã¦ã³ã­ã¼ããããã®ã§ã
// bootstrap icons ã®ã¢ããã°ã¬ã¼ãæã«ã¯æ¬å®¶ãã font/bootstrap-icons.scss åã³ font/fonts/* ããã¦ã³ã­ã¼ããã¦ãã ããã
// æé ä¾)
// git clone --depth 1 https://github.com/twbs/icons.git
// cp icons/font/bootstrap-icons.scss ~/workspace/arschool/ars-crm/resources/sass/modules
// cp icons/font/fonts/* ~/workspace/arschool/ars-crm/resources/sass/modules/fonts/
@import "./modules/bootstrap-icons.scss";


//@import '~bulma/bulma';

// Font Awesome
//@import "~font-awesome-scss/scss/font-awesome.scss";

.navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}


// arschool add
@import 'framework/dashboard';

.table.show {
    th {
        white-space: nowrap;
    }

    td {
        //white-space: pre-wrap;
        //word-break: break-all;
        //word-wrap: break-word;
    }
}

.pre-wrap {
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
}

.no-wrap {
    white-space: nowrap;
}

form {
    .error {
        color: #ff0000;
    }
}


.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 350px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

@import "crm";

