@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";

$darkGray: #363636;

$pink: #EE3D96;
$pinkT: rgba(238, 61, 150, 0.5);
$green: #7fc349;
$greenT: rgba(127, 195, 73, 0.7);
$darkGreen: #598c21;
$orange: #f6993f;
$yellow: #ffed4a;
$indigo: #6574cd;
$cyan: #17a2b8;

$categoryColor: #5097b4;

$grayBackground: #efefef;


// å¥åã¨ã©ã¼å±é
.ars-input-error-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e3342f;
}

.small {
    font-size: 80%;
}

.inline-block {
    display: inline-block !important;
}

.form-control {
    padding: 0.2rem 0.75rem;

    &::placeholder {
        color: #ccc;
    }
}

.form-control:focus {
    box-shadow: 0 0 0 0.1rem rgba(52, 144, 220, 0.25);
}

.form-control-sm {
    padding: 0.2rem 0.5rem;
}

form {
    .width-ss {
        width: 2.5em;
    }

    .width-s {
        width: 5em;
    }

    .width-ssm {
        width: 7em;
    }

    .width-sm {
        width: 10em;
    }

    .width-m {
        width: 15em;
    }

    .width-ml {
        width: 22em;
    }

    .width-l {
        width: 30em;
    }

    input.invalid {
        border-color: #f6993f;
    }

    input.invalid, select.invalid, textarea.invalid {
        border-color: $orange;

        &:focus {
            border-color: $orange;
            box-shadow: 0 0 0 0.1rem rgba(246, 153, 63, 0.47);
        }
    }

    .input-inline {
        display: inline-block;
    }

    label.radio-label {
        margin-right: 0.8rem;
    }

    .error-msg {
        color: $orange;
        font-size: 85%;
    }
}

div.server-errors {
    border: 1px solid $orange;
    color: $orange;
    padding: 0.5em;
    border-radius: 4px;
    margin-bottom: 1.8rem;

    div.error-box-title {
        margin-bottom: 8px;

        i {
            margin-right: .4em;
        }
    }

    p {
        margin-bottom: 4px;
        padding-left: .5em;

        i {
            margin-right: .4em;
        }
    }
}

table {
    thead {
        th {
            span.sort {
                display: inline-block;

                &.active {
                    span.arrow {
                        opacity: 1;
                        color: $pink;
                    }
                }

                span.arrow {
                    display: inline-block;
                    //vertical-align: middle;
                    //width: 0;
                    //height: 0;
                    color: #999;
                    margin-left: 5px;
                    opacity: 0.5;

                    &:before {
                        //color: #999;
                        margin-right: 0.1rem;
                    }

                    &.asc {
                        &:before {
                            @extend .fas;
                            content: "\f0d8";
                        }
                    }

                    &.dsc {
                        &:before {
                            @extend .fas;
                            content: "\f0d7";
                        }
                    }

                }
            }

        }
    }

    //th.active,th span.active {
    //  color: $darkGray;
    //}
    //
    //th.active .arrow,th span.active .arrow {
    //  color: $pink;
    //  opacity: 1;
    //}

    //th{
    //  .arrow {
    //    display: inline-block;
    //    vertical-align: middle;
    //    width: 0;
    //    height: 0;
    //    margin-left: 5px;
    //    opacity: 0.5;
    //  }
    //
    //  .arrow.asc {
    //    &:before {
    //      color: #999;
    //      @extend .fas;
    //      content: "\f0d8";
    //      margin-right: 0.1rem;
    //    }
    //    //border-left: 4px solid transparent;
    //    //border-right: 4px solid transparent;
    //    //border-bottom: 4px solid $darkGray;
    //  }
    //
    //  .arrow.dsc {
    //    //border-left: 4px solid transparent;
    //    //border-right: 4px solid transparent;
    //    //border-top: 4px solid $darkGray;
    //    &:before {
    //      color: #999;
    //      @extend .fas;
    //      content: "\f0d7";
    //      margin-right: 0.1rem;
    //    }
    //  }
    //}
}


nav.sidebar {
    font-size: 0.9rem;
}

a.button {
    &:hover {
        text-decoration: none;
    }
}

a.btn-round {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    border-radius: 30px;
    padding: .4em 1.7em;
    transition: all .2s ease;

    background: #fff;
    border: 1px solid #aaaaaa;
    color: #666666;
    //filter: drop-shadow(2px 2px 1px rgba(150, 150, 150, .8));


    &:hover {
        color: #8c8c8c;
        text-decoration: none;
        opacity: 0.5;
    }
}

a.btn-router {
    &:before {
        color: #999;
        @extend .fas;
        content: "\F067";
        margin-right: 0.1rem;
    }
}


div.title-area {
    padding-top: 1rem;
    margin-bottom: 1.5rem;

    div.page-sub-title {
        font-size: 0.9rem;
    }

    h2.page-title {
        font-size: 1.3rem;
        line-height: 2.25rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 2rem;
        margin-bottom: 0;
    }

    a.btn-navi-reg {

    }
}

section.input-area {
    font-size: 0.9rem;
}

table.list-table {
    font-size: 0.9rem;
}


//header
.header-navbar {
    a.navbar-brand {
        img {
            height: 2rem;
        }
    }

    .hamburger-button {
        @media (max-width: 500px) {
            display: none;
        }
    }

    .sp-menu-bars {
        @media (min-width: 501px) {
            display: none;
        }
        @media (max-width: 500px) {
            display: inline-block;
            color: #fff;
            i {
                font-size: 22px;
            }
            span {
                font-size: 18px;
                display: inline-block;
                position: relative;
                top: -4px;
                margin-left: 4px;
            }

        }
    }
}


//ã¤ãã³ãåå èä¸è¦§ãã¼ã¸
.event-applicant-component {
    div.title {
        margin-bottom: 2rem;

        h2 {
            font-size: 1.5rem;
        }

        h3 {
            font-size: 1.5rem;
        }
    }

    section.list-section {
        margin-bottom: 3rem;

        h4 {
            font-size: 1.1rem;
        }
    }

    table.applicant-list {
        thead {
            tr {
                th {
                    border-top: none;
                }
            }
        }
    }
}

//æªã­ã°ã¤ã³ãã¼ã¸
.unsigned-in-layout {
    .container {
        & > a.navbar-brand {
            background: transparent;
            box-shadow: none;

            img {
                height: 2rem;
            }
        }

    }
}

.cursor-alias {
    cursor: alias;
}

.cursor-all-scroll {
    cursor: all-scroll;
}

.cursor-auto {
    cursor: auto;
}

.cursor-cell {
    cursor: cell;
}

.cursor-context-menu {
    cursor: context-menu;
}

.cursor-col-resize {
    cursor: col-resize;
}

.cursor-copy {
    cursor: copy;
}

.cursor-crosshair {
    cursor: crosshair;
}

.cursor-default {
    cursor: default;
}

.cursor-e-resize {
    cursor: e-resize;
}

.cursor-ew-resize {
    cursor: ew-resize;
}

.cursor-grab {
    cursor: -webkit-grab;
    cursor: grab;
}

.cursor-grabbing {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.cursor-help {
    cursor: help;
}

.cursor-move {
    cursor: move;
}

.cursor-n-resize {
    cursor: n-resize;
}

.cursor-ne-resize {
    cursor: ne-resize;
}

.cursor-nesw-resize {
    cursor: nesw-resize;
}

.cursor-ns-resize {
    cursor: ns-resize;
}

.cursor-nw-resize {
    cursor: nw-resize;
}

.cursor-nwse-resize {
    cursor: nwse-resize;
}

.cursor-no-drop {
    cursor: no-drop;
}

.cursor-none {
    cursor: none;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-progress {
    cursor: progress;
}

.cursor-row-resize {
    cursor: row-resize;
}

.cursor-s-resize {
    cursor: s-resize;
}

.cursor-se-resize {
    cursor: se-resize;
}

.cursor-sw-resize {
    cursor: sw-resize;
}

.cursor-text {
    cursor: text;
}

.cursor-w-resize {
    cursor: w-resize;
}

.cursor-wait {
    cursor: wait;
}

.cursor-zoom-in {
    cursor: zoom-in;
}

.cursor-zoom-out {
    cursor: zoom-out;
}

// for LessonUserStatusRowComponent
.lesson-row-fa-dark {
    color: $darkGray;
}

.lesson-row-fa-gray {
    color: #ccc;
}

// for LessonUserStatusRowComponent and ChildLessonRowComponent
.lesson-memo {
    max-width: 20rem;
    height: 6rem;
    overflow: auto;
    white-space: pre-line;
}

// for components/lesson_time
#lesson-time-show hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#lesson-time-show form {
    margin-bottom: 3rem;
}

#lesson-time-show table.lesson-time-table {
    @media (max-width: 500px) {
        thead {
            display: none;
        }
        tbody {
            tr {
                border-top: 1px solid #ccc;

                td {
                    display: block;
                    width: 100%;
                    border: none;

                    &.lesson-row-child--bosy {
                        display: none;
                    }
                }
            }
        }

    }
}

.ars-tag {
    font-weight: bold;
    border-radius: 0 5px 5px 0;
    border-width: 1px;
    border-style: solid;
    border-color: #999;
    padding: 3px 6px 3px 5px;
}

@import 'page/user';
@import 'page/invoice';
