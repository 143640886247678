.invoice-add-page, .invoice-edit-page {
    width: 640px;

    table.invoice-item-tbl {
        margin-bottom: 1rem;

        thead {
            tr {
                th {
                    font-weight: 400;
                    padding-left: 0.1rem;
                    padding-right: 0.1rem;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 0.1rem;

                    input.text {
                        width: 100%;
                    }

                    &.price-label {
                        font-size: .9rem;
                        text-align: right;
                        padding-right: 1rem;
                    }

                    &.price-val {
                        font-size: 1rem;
                        text-align: right;
                    }

                    &.remove {
                        padding-left: 0.5rem;
                    }

                    .item-remove-btn {
                        padding: 0.15rem 0.4rem;
                        color: #777;
                        border-color: #777;
                    }
                }
            }
        }

    }

    div.item-btn-area {
        margin-bottom: 2rem;

        button {
            margin-right: 0.2rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}



