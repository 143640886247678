.family-list-page {
    table.list-table {
        div.child {
            display: flex;
            border-bottom: 1px solid #ddd;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }

            .child-data {
                flex: 1 1 30%;
            }

            .course-data {
                flex: 1 1 70%;

                .course {
                    border-bottom: 1px solid #ddd;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }

        }

    }
}


.family-show-page {
    display: flex;

    & > .main-content {
        flex: 0 0 70%;
    }

    & > .side-content {
        flex: 0 0 30%;
        padding: 0 1rem 0 2rem;

        section.side-menu-section {
            margin-top: 2rem;

            button {
                display: block;
                width: 9rem;
                margin: 0 auto 1rem;
            }
        }

    }

    table.children-table {
        .courses {
            margin-bottom: 0.5rem;

            .course {
                display: flex;
                border-bottom: 1px solid #ddd;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                    border-bottom: none;
                }

                & > .data {
                    flex: 1 1 70%;
                }

                & > .action {
                    flex: 1 1 30%;
                }

            }
        }

    }


    .component {
        h3.user-name {
            font-size: 1.1rem;
        }
    }

    .parent-add-component {
    }

}

