body {
    height: 100vh;
    overflow-x: hidden;
    font-size: .875rem;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

/**
 * ãµã¤ããã¼ãéãã¦ããå ´åãå¨ä½ã®å¹ããã¡ããä½è£ãæã£ã¦ç¢ºä¿
 */
.row.collapse > .crm-main {
    max-width: 95vw;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 95%;
}

/**
 * ãµã¤ããã¼ãéãã¦ããå ´åããµã¤ããã¼ãé¤å¤ããå¹ãç¢ºä¿ãã(ãµã¤ããã¼ã¯200pxã ãä½è£ããã£ã¦ç¢ºä¿)
 */
.row.collapse.show > .crm-main {
    max-width: calc(100vw - 250px);
    flex-grow: 0;
    flex-shrink: 0;
    /* ä½è£ãæã£ã¦ 250px å¼ãã¦ã */
    flex-basis: calc(100vw - 250px);
    padding-bottom: 100px;
}

/*
 * Sidebar
 * ééã§ããããã«ä»¥ä¸ãåèã«ãã¦ãã
 * https://stackoverflow.com/questions/48996084/create-a-responsive-navbar-sidebar-drawer-in-bootstrap-4
 * https://www.codeply.com/go/XJE8LOdX8k
 */

.w-sidebar {
    z-index: 100; /* Behind the navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    width: 200px;
    max-width: 200px;
}

.row.collapse {
    margin-left: -200px;
    left: 0;
    transition: margin-left .15s linear;
}

.row.collapse.show {
    margin-left: 0 !important;
}

.row.collapsing {
    margin-left: -200px;
    left: -0.05%;
    transition: all .15s linear;
}

/* æ¨ªå¹ãç­ãã¨ãã«ã¯ãµã¤ããã¼ãéè¡¨ç¤ºã«ãã */
@media (max-width: 830px) {

    .row.collapse,
    .row.collapsing {
        margin-left: 0 !important;
        left: 0 !important;
        overflow: visible;
    }

    .row > .sidebar.collapse {
        display: flex !important;
        margin-left: -100% !important;
        transition: all .25s linear;
        position: fixed;
        z-index: 1050;
        max-width: 0;
        min-width: 0;
        flex-basis: auto;
    }

    .row > .sidebar.collapse.show {
        margin-left: 0 !important;
        width: 200px;
        max-width: 200px;
        min-width: initial;
    }

    .row > .sidebar.collapsing {
        display: flex !important;
        margin-left: -10% !important;
        transition: all .2s linear !important;
        position: fixed;
        z-index: 1050;
        min-width: initial;
    }

    .row.collapse.show > .crm-main {
        max-width: 98vw;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 98%;
    }
}

@media (max-width: 500px) {
    .row.collapse.show > .crm-main {
        margin-left: auto;
        margin-right: auto;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

}

/* ãµã¤ããã¼ããã¾ã§
 * -------------------------------------------------
 */

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}

.sidebar .nav-link .feather,
.sidebar .nav-link i.fa {
    margin-right: 4px;
    color: #999;
}

.sidebar .nav-link i.fa {
    font-size: 16px;
    vertical-align: text-bottom;
}

.sidebar .nav-link.active {
    color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather,
.sidebar .nav-link:hover i.fa,
.sidebar .nav-link.active i.fa {
    color: inherit;
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
    padding-top: 48px; /* Space for fixed navbar */
}

/*
 * Navbar
 */

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
    width: 200px;
    max-width: 200px;
}

.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}
